import { useSearchParams } from "react-router-dom"
import React, { useEffect, useState, useRef } from 'react'
import { Controller, useForm } from "react-hook-form"
import '../form/form-register.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import API_URL from '../../Api/index'
import Swal from "sweetalert2"
import { Typeahead } from "react-bootstrap-typeahead"

const FormRegister = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [kabkota, setKabKota] = useState([])
    const [provinsi, setProvinsi] = useState([])
    const [sekolah, setSekolah] = useState([])
    const [jenjang, setJenjang] = useState([])
    const [id_propinsi, setIdPropinsi] = useState('')
    const [code_jenjang, setCodeJenjang] = useState('')
    const [kode_sekolah, setCodeSekolah] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)

    const [searchParams] = useSearchParams();
    const type = searchParams.get("edufest");

    const typeaheadRef = useRef(null);

    const clearTypeahead = () => {
        typeaheadRef.current.clear();
    };

    const onSubmit = async (data) => {
        setBtnLoading(true)
        data = {...data, id_konten: API_URL.id_event, keterangan: type == 'Lv3' ? 'NIECWEF' : '', sekolah: kode_sekolah}
        await API_URL.eventUrl.post('register', data)
        .then(res => {
            Swal.fire({
                title: 'Registrasi Berhasil',
                icon: 'success',
                type: 'success',
                confirmButtonColor: '#3085d6',
                text: 'Anda telah mendaftar di event NIEC World Education Festival, cek email/spam anda untuk info selanjutnya',  
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
            setBtnLoading(false)
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
        getCityByEvent();
        getProvinsi();
        getJenjang();
    },[])

    const getCityByEvent = async () => {
        await API_URL.eventUrl.get('world-education-festival/master-event/'+API_URL.id_event)
        .then(response => response.data.data.event_lokasi)
        .then(result => {
            setKabKota(result)
        })
        .catch(error => console.log(error))
    }

    const getProvinsi = async () => {
        await API_URL.baseUrl.get('master/provinces')
        .then(response => response.data.data)
        .then(result => {
            setProvinsi(result)
        })
        .catch(error => console.log(error))
    }

    const getJenjang = async () => {
        await API_URL.baseUrl.get('master/jenjang')
        .then(response => response.data.data)
        .then(result => {
            setJenjang(result)
        })
        .catch(error => console.log(error))
    }

    const pilihPropinsi = async (e) => {
        setIdPropinsi(e.target.value);
        setCodeJenjang('');
        clearTypeahead();
    }

    const pilihSekolahJenjang = async (e) => {
        setCodeJenjang(e.target.value)
        clearTypeahead();
        await API_URL.baseUrl.get('sekolah-indonesia?province_id='+id_propinsi+'&jenjang='+e.target.value)
        .then(response => response.data.data)
        .then(result => {
            setSekolah(result)
        })
        .catch(error => console.log(error))
    }

    const changeSekolah = (kode) => {
        kode.length > 0 ? setCodeSekolah(kode[0].kode_sekolah) : setCodeSekolah('')
    }

    return (
        <>
            <div className="register">
                <div className='form-register'>
                    <h2>Form Pendaftaran</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-input">
                            <label htmlFor="jadwal" className="form-label">Pilih Jadwal</label>
                            <select {...register("id_kabkota", {required:'Jadwal Wajib Dipilih'})} className={`form-control ${errors.id_kabkota?.message && 'is-invalid'}`}>
                                <option value=''>--Pilih Jadwal--</option>
                                {
                                    kabkota?.length > 0 ? 
                                    kabkota.map((kota, i) => {
                                        return <option key={i} value={kota.kabupaten.id_kabkota}>{kota.kabupaten.nama_kabkota}</option>
                                    })
                                    :
                                    ''
                                }
                            </select>
                            {errors.id_kabkota?.message && (<div className="invalid-feedback">{errors.id_kabkota?.message}</div>) }
                        </div>
                        <div className="form-input">
                            <label htmlFor="email" className="form-label">Email </label>
                            <input {...register("email", {required:'Email Wajib diisi', pattern: {value:/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, message: 'Email tidak valid'}})}  type="text" className={`form-control ${errors.email?.message && 'is-invalid'}`} id="email" placeholder="name@example.com" />
                            {errors.email?.message && (<div className="invalid-feedback">{errors.email?.message}</div>) }
                        </div>
                        <div className="form-input">
                            <label htmlFor="nama" className="form-label">Nama</label>
                            <input {...register("name", {required:'Nama Lengkap Wajib Diisi'})} type="text" className={`form-control ${errors.name?.message && 'is-invalid'}`} id="nama" placeholder="Nama Lengkap" />
                            {errors.name?.message && (<div className="invalid-feedback">{errors.name?.message}</div>) }
                        </div>
                        <div className="form-input">
                            <label htmlFor="provinsi-sekolah" className="form-label">Provinsi Sekolah</label>
                            <select className={`form-control`} onChange={(e) => pilihPropinsi(e)}>
                                <option value='' defaultValue>--Pilih Provinsi--</option>
                                {
                                    provinsi?.length > 0 ? 
                                    provinsi.map((prov, i) => {
                                        return <option key={i} value={prov.id}>{prov.province_name}</option>
                                    })
                                    :
                                    ''
                                }
                            </select>
                            {errors.provinsi_sekolah?.message && (<div className="invalid-feedback">{errors.provinsi_sekolah?.message}</div>) }
                        </div>
                        <div className="form-input">
                            <label htmlFor="provinsi-sekolah" className="form-label">Jenjang Pendidikan</label>
                            <select className={`form-control`} value={code_jenjang} onChange={(e) => pilihSekolahJenjang(e)}>
                                <option value='' defaultValue>--Pilih Jenjang--</option>
                                {
                                    jenjang?.length > 0 ? 
                                    jenjang.map((jenjang, i) => {
                                        return <option key={i} value={jenjang.code_jenjang}>{jenjang.jenjang_pendidikan}</option>
                                    })
                                    :
                                    ''
                                }
                            </select>
                            {errors.provinsi_sekolah?.message && (<div className="invalid-feedback">{errors.provinsi_sekolah?.message}</div>) }
                        </div>
                        <div className="form-input">
                            <label htmlFor="sekolah" className="form-label">Sekolah</label>
                            <Typeahead
                                id='basic-typeahead'
                                labelKey='nama_sekolah'
                                options={sekolah}
                                onChange={changeSekolah}
                                placeholder='--Pilih Sekolah--'
                                ref={typeaheadRef}
                            />
                            {errors.sekolah?.message && (<div className="invalid-feedback">{errors.sekolah?.message}</div>) }
                        </div>
                        <div className="form-input">
                            <label htmlFor="phone" className="form-label">No Whatsapp</label>
                            <Controller
                                control={control}
                                name="phone"
                                rules={{ required: 'Nomor Whatsapp Wajib Diisi'}}
                                render={({ field: { ref, ...field } }) => (
                                    <PhoneInput
                                        {...field}
                                        inputExtraProps={{
                                        ref,
                                        required: true,
                                        }}
                                        country={"id"}
                                        countryCodeEditable={false}
                                        inputClass={`${errors.phone?.message && 'input-invalids'}`}
                                        inputStyle={{width:'100%', height: '45px'}}
                                    />
                                )}
                            />
                            {errors.phone?.message && (<div className="text-invalids">{errors.phone?.message}</div>) }
                        </div>
                        <div className="form-input">
                            <label htmlFor="kode_referal" className="form-label">Kode Referral</label>
                            <input {...register("kode_referal")}  type="text" className="form-control" id="kode" placeholder="Hanya diisi jika mendapat kode undangan dari Ambassador"/>
                        </div>
                        <div className="form-input">
                            <button disabled={btnLoading ? true : false} type='submit' className='btn btn-register'>{btnLoading ? 'Daftar...' : 'Daftar'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default FormRegister